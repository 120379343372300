import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import { Badge, CardBody, CardSubtitle } from 'reactstrap'
import { slugify } from '../util/utilityFunctions'
import authors from '../util/authors'
import { DiscussionEmbed } from 'disqus-react'
import "katex/dist/katex.min.css"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {Im} from '../components/PageTitle/styles'
import {Ptd, Ptd2} from '../components/PageTitle/styles'


const SinglePost = ({ data, pageContext, location }) => {
  const post = data.mdx
  const author = authors.find(x => x.name === post.frontmatter.author)
  const baseUrl = 'https://homotopias.com'

  const disqusShortname = 'homotopias-com'
  const disqusConfig = {
    identifier: data.mdx.id,
    title: post.frontmatter.title,
    url: baseUrl + pageContext.slug,
  }
  


  return (
    <>

    <Im
          className="card-image-top"
          fluid={post.frontmatter.image.childImageSharp.fluid}
        />

<Ptd2 >
          </Ptd2>
    <Layout
      pageTitle={post.frontmatter.title}
      postAuthor={author}
      authorImageFluid={data.file.childImageSharp.fluid}
      
    >

      <Im
          className="card-image-top"
          authorImageFluid={data.file.childImageSharp.fluid}
          
        />


      <SEO
        author={post.frontmatter.author}
        title={post.frontmatter.title}
        keywords={post.frontmatter.tags}
        description={post.frontmatter.description}
        url={baseUrl}
        pathname={location.pathname}
      />
 

    <MDXRenderer>{post.body}</MDXRenderer> 

    
        
        <CardBody>
       
        
          <CardSubtitle>
            <span className="text-info">{post.frontmatter.date}</span> por{' '}
            <span className="text-info">{post.frontmatter.author}</span>
          </CardSubtitle>
          
          <ul className="post-tags">
            {post.frontmatter.tags.map(tag => (
              <li key={tag}>
                <Link to={`/tag/${slugify(tag)}`}>
                  <Badge color="primary">{tag}</Badge>
                </Link>
              </li>
            ))}
          </ul>
        </CardBody>
       
      <h3 className="text-center">Comparte este Post</h3>
      <div className="text-center social-share-links">
        <ul>
          <li>
            <a
              href={
                'https://www.facebook.com/sharer/sharer.php?u=' +
                baseUrl +
                '/' + pageContext.slug
              }
              className="facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f fa-2x" />
            </a>
          </li>
          <li>
            <a
              href={
                'https://twitter.com/share?url=' +
                baseUrl +
                '/' + pageContext.slug +
                '&text=' +
                post.frontmatter.title +
                '&via' +
                'twitterHandle'
              }
        
              className="twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter fa-2x" />
            </a>
          </li>
          <li>
        
            <a
              href={
                'https://plus.google.com/share?url=' +
                baseUrl +
                '/' + pageContext.slug
              }
              className="google"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-google fa-2x" />
            </a>
          </li>
          <li>
            <a
              href={
                'https://www.linkedin.com/shareArticle?url=' +
                baseUrl +
                '/' +
                pageContext.slug
              }
              className="linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin fa-2x" />
            </a>
          </li>
        </ul>
      </div>
      <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
    </Layout>
    
    </>
  )
}

export const postQuery = graphql`

  query blogPostBySlug($slug: String!, $imageUrl: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        author
        date(formatString: "MMM Do YYYY")
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    file(relativePath: { eq: $imageUrl }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`


export default SinglePost
